import { useState } from 'react'
import axios from 'axios'

export const Contact = (props) => {
  const reasons = [
    "Interested Customer",
    "Delivery Driver & Handler",
    "Mobile Mechanic",
    "Tire Shop",
    "Auto Parts Store",
    "Auto Repair Shop",
    "Car Wash & Detailing",
    "Car diagnosis",
    "Towing"
  ]

  const [{ email, name, user_type }, setState] = useState({
    email: '',
    name: '',
    user_type: reasons[0]
  })

  const [disabled, setDisabled] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setDisabled(true)

    axios.post("/newsletter", { email, name, user_type })
      .then(() => alert("Success!"))
      .catch(() => alert("Something went wrong..."))
      .finally(() => setDisabled(false))
  }

  const handleInstagram = (e) => {
    window.location = "https://www.instagram.com/centaurconnect/"
  }

  const handleFacebook = (e) => {
    window.location = "https://www.facebook.com/centaurconnect"
  }

  return (
    <div>
      <div id='contact'  className="form-tab">
        <div className='container'>
          <div className='col-md-7'>
            <div className='row'>
              <div className='section-title'>
                <h2>Sign Up to be part of Centaur Connect</h2>
              </div>
              <form name='sentMessage' validate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='form-group-lg'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='Your name...'
                        required
                        maxLength={255}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <p></p>

                <div className='row'>
                  <div className='col-md-12'>
                    <div className='form-group-lg'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Your e-mail...'
                        required
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <p></p>

                <div className='row'>
                  <div className='col-md-12'>
                    <div className='form-group-lg'>
                        <h4>What's your reason for joining our platform?</h4>
                        <select name="user_type" className="form-control" onChange={handleChange}>
                            {reasons.map((item, index) => <option selected={index == 0} value={item}>{item}</option>)}
                        </select>
                  </div>
                </div>
                </div>
                <button type='submit' className='btn btn-custom btn-lg' disabled={disabled}>
                  Submit
                </button>
              </form>
            </div>
          </div>

          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3 style={{ marginBottom: "0", paddingBottom: "0" }}>Contact Info</h3>
              <div className='contact-item'>
                <ul>
                  <li style={{ fontWeight: "bold" }}>Car Diagnosis starting at $59.99 - $79.99 from one of our Specialized Mobile Mechanics Today</li>
                  <li>&nbsp;</li>
                  <li style={{ fontWeight: "bold" }}>Call or text us to find out more about our services!</li>
                </ul>
              </div>

              <p></p>

              <p>
                <span>
                  <i className='fas fa-map-marker-alt'></i> Locations
                </span>{' '}
                Stockton, Lodi, Manteca, Lathrop, Sacramento, San Francisco, Las Vegas, Antioch, Richmond, San Pablo, Berkeley, Elk Grove
              </p>

              <p>
                <span>
                  <i className='fas fa-phone'></i> Phone
                </span>{' '}
                {props.data ? props.data.phone : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fas fa-envelope'></i> Email
                </span>{' '}
                {props.data ? props.data.email : 'loading'}
              </p>
            </div>
            <div className='contact-item' onClick={handleInstagram} style={{ cursor: "pointer" }}>
              <p>
                  <span>
                    <i className='fab fa-instagram'></i> Instagram
                  </span>{' '}
                  @centaurconnect
              </p>
            </div>
            <div className='contact-item' onClick={handleFacebook} style={{ cursor: "pointer" }}>
              <p>
                  <span>
                    <i className='fab fa-facebook'></i> Facebook
                  </span>{' '}
                  CentaurConnect
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
