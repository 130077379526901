import ParticlesBg from "particles-bg";

export const Header = (props) => {
    return (
        <header id='header'>
            <div className='intro'>
                <ParticlesBg type="cobweb" num={50} color="#ffffff" bg={{ zIndex: 0, position: "absolute", top: 0, background: "linear-gradient(to right, #6372ff 0%, #5ca9fb 100%)" }} />
                <div className='overlay'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-8 col-md-offset-2'>
                                <div style={{ height: "calc(100vh - 80px)", display: "flex", alignItems: "center", marginTop: 80 }}>
                                    <div>
                                        <h1 style={{ marginBottom: 20 }}>
                                            <img src="/img/centaur-connect-logo.svg" alt="Logo" className="header-logo"></img>
                                            <span></span>
                                        </h1>
                                        <p className="text-center">{props.data ? props.data.paragraph : 'Loading'}</p>
                                        <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
                                          <div className='action-button'>
                                                <a
                                                    href='#order'
                                                    className='btn btn-custom btn-lg page-scroll ms-4'
                                                >
                                                    Order
                                                </a>
                                            </div>
                                            
                                            <div className='action-button'>
                                                <a
                                                    href='#contact'
                                                    className='btn btn-custom btn-lg page-scroll'
                                                >
                                                    Learn More
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}
