import { useState } from 'react'
import axios from 'axios'

export const Order = (props) => {
  const [{email, name, phone, address, city, model, order}, setState] = useState({
    email: '',
    name: '',
    phone: '',
    address: '',
    city: '',
    model: '',
    order: '',
  })

  const [disabled, setDisabled] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    setDisabled(true)

    axios.post("/order", { email, name, phone, address, city, model, order })
      .then(() => alert("Success!"))
      .catch(() => alert("Something went wrong..."))
      .finally(() => setDisabled(false))
  }

  return (
    <div id='order'>
      <div className="form-tab">
        <div className='container'>
          <div className='col-md-7'>
            <div className='row'>
              <div className='section-title'>
                <h2>Place an order</h2>
              </div>
              <form name='sentMessage' validate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='form-group-lg'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='Your name...'
                        required
                        maxLength={255}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <p></p>

                <div className='row'>
                  <div className='col-md-12'>
                    <div className='form-group-lg'>
                      <input
                        type='text'
                        id='phone'
                        name='phone'
                        className='form-control'
                        placeholder='Your phone...'
                        required
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <p></p>

                <div className='row'>
                  <div className='col-md-12'>
                    <div className='form-group-lg'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Your e-mail...'
                        required
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <p></p>

                <div className='row'>
                  <div className='col-md-12'>
                    <div className='form-group-lg'>
                      <input
                        type='text'
                        id='address'
                        name='address'
                        className='form-control'
                        placeholder='Your address...'
                        required
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <p></p>

                <div className='row'>
                  <div className='col-md-12'>
                    <div className='form-group-lg'>
                      <input
                        type='text'
                        id='city'
                        name='city'
                        className='form-control'
                        placeholder='Your city...'
                        required
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <p></p>

                <div className='row'>
                  <div className='col-md-12'>
                    <div className='form-group-lg'>
                      <input
                        type='text'
                        id='model'
                        name='model'
                        className='form-control'
                        placeholder='Your vehicle model...'
                        required
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <p></p>

                <div className='row'>
                  <div className='col-md-12'>
                    <div className='form-group-lg'>
                      <textarea
                        id='order'
                        name='order'
                        className='form-control'
                        placeholder="Describe your car problem, the car part you want, or what service you'd like for your vehicle..."
                        required
                        rows="5"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <p></p>

                <button type='submit' className='btn btn-custom btn-lg' disabled={disabled}>
                  Submit
                </button>
              </form>
            </div>
          </div>

          <div className='col-md-4 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3 style={{ marginBottom: "0", paddingBottom: "0" }}>Order information</h3>
              <div className='contact-item'>
                <ul>
                  <li style={{ fontWeight: "bold" }}>Price: $59.99 - $79.99</li>
                  <li>&nbsp;</li>
                  <li style={{ fontWeight: "bold" }}>You can also order directly by phone: (209)-891-8344</li>
                  <li>&nbsp;</li>
                  <li style={{ fontWeight: "bold" }}>After you place an order we will call you asking for confirmation</li>
                  <li>&nbsp;</li>
                  <li style={{ fontWeight: "bold" }}>Payment options: Cash, Venmo, Cash App, Zelle, Bitcoin</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
