import { useState, useEffect } from 'react'
import { Navigation } from './components/navigation'
import { Header } from './components/header'
import { Features } from './components/features'
import { About } from './components/about'
import { Services } from './components/services'
//import { Gallery } from './components/gallery'
//import { Testimonials } from './components/testimonials'
import { Footer } from './components/footer'
import { Contact } from './components/contact'
import JsonData from './data/data.json'
import SmoothScroll from 'smooth-scroll'

import { CautionOverlay } from './components/caution_overlay'
import { Order } from "./components/order"

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
})

const App = () => {
  const [landingPageData, setLandingPageData] = useState({})
  useEffect(() => {
    setLandingPageData(JsonData)
  }, [])

  return (
    <div>
      { process.env.REACT_APP_SHOW_CAUTION === "true" && <CautionOverlay /> }
      
      <Navigation />
      <Header data={landingPageData.Header} />
      <About data={landingPageData.About} />
      <Order />
      <Features data={landingPageData.Features} />
      <Services data={landingPageData.Services} />
      {/*
      <Gallery />
      <Testimonials data={landingPageData.Testimonials} />
      */}
      <Contact data={landingPageData.Contact}/>
      <Footer />
    </div>
  )
}

export default App
