export const CautionOverlay = () => {
  function getNumberOfDays(lastPayment) {
    const lastPaymentDate = new Date(lastPayment)
    const timeBetweenPayment = new Date().getTime() - lastPaymentDate.getTime()
    return Math.round(timeBetweenPayment / (1000 * 60 * 60 * 24)) 
  }

  return (
    <div className="caution-overlay">
      <h2 style={{ 
        fontSize: "75px"
      }}>Caution</h2>

      <h2 style={{ textAlign: "center", marginTop: "15px" }}>The owner of this business has paid only $50 out of agreed $150 since {getNumberOfDays("2023-09-10")} days ago</h2>
    </div>
  )
}
