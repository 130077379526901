export const About = (props) => {
  return (
    <div id='about'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 about-image-wrapper'>
            {' '}
            <img src='img/centaur-connect-logo-black-text-vertical.svg' className='img-responsive' alt='' />{' '}
          </div>
          <div className='col-12'>
            <div className='about-text'>
                <div className="section-title text-center">
                    <h2>About Us</h2>
                </div>
              <p style={{ margin: "0 15px" }}>{props.data ? props.data.paragraph : 'loading...'}</p>
              <p style={{ margin: "0 15px 20px 15px" }}>- Jeffrey J. Calero, CEO &amp; Founder</p>
              <h3 className="ml-4">Why Choose Us?</h3>
              <div className='list-style'>
                <div className='col-lg-6 col-sm-6 col-xs-12'>
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}><i className="fas fa-greater-than"></i> {d}</li>
                        ))
                      : 'loading'}
                  </ul>
                </div>
                <div className='col-lg-6 col-sm-6 col-xs-12'>
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}><i className="fas fa-greater-than"></i> {d}</li>
                        ))
                      : 'loading'}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
